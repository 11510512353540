import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Badge } from 'react-bootstrap';

import { use } from '../../redux/factory';
import { SmallSpinner, DataTable } from '../shared/ReactToolbox';
import TaskActions from './TaskActions';
import { getTaskName } from './Task';

export const orderTasksList = tasksList =>
  Object.values(tasksList)
    .sort((t1, t2) => t1.project.order > t2.project.order ? 1 : -1);

const rowClassName = ({ is_flagged }) => is_flagged ? 'task-row-flagged' : null;

const Location = ({ cursor, hasLocation = true, ...props }) => (
  <Badge
    bg={hasLocation ? "primary" : "secondary"}
    style={{ ...cursor ? { cursor } : {} }}
    className='location'
    {...props}
  />
);


const TasksList = () => {
  const { tasksList, selectTask, getTasksList, startTask } = use.tasks();
  const user = useSelector(({ auth }) => auth.user);
  const getLocationName = (task) => {
    const location = task.task_template.locations[0];
    // const location = user.locations.find(({ id }) => id === locationId);
    if (!location) return '';
    return location.name;
  }

  const filterColumn = task => {
    const text = (
      getTaskName(task)
      + ' ' + (task.contact ? task.contact.last_name : '')
      + ' ' + task.project.name
      + ' ' + task.task_template.role.name
      + ' ' + getLocationName(task)
    )
    return text
  };
  const columns = [
    {
      name: 'Taak',
      selector: task => getTaskName(task),
      orderBy: task => getTaskName(task),
    },
    {
      name: 'Klant',
      selector: task => task.contact ? task.contact.last_name : <i>Niet gevonden</i>,
      orderBy: task => task.contact ? task.contact.last_name : '',
    },
    {
      name: 'Project',
      selector: task => task.project.name,
      orderBy: task => task.project.name,
    },
    {
      className: 'd-none d-md-table-cell',
      name: 'Rol',
      orderBy: task => task.task_template.role.name,
      selector: task => (
        <Badge
          bg="primary"
          className='role'
        >
          {task.task_template.role.name}
        </Badge>
      ),
    },
    {
      className: 'd-none d-md-table-cell',
      name: 'Locaties',
      orderBy: task => getLocationName(task),
      selector: task =>
        task.task_template.locations.map(({ name, id: locationId }, index) =>
          <Location
            className='location'
            hasLocation={!!user.locations.find(({ id }) => id === locationId)}
            key={index}
          >
            {name}
          </Location>
        ),
    },
    {
      name: 'Acties',
      selector: task => <>
        <div className='float-end'>
          <TaskActions task={task} />
        </div>
      </>,
    },
  ];
  const assignedTasks = Object.values(tasksList).filter(({ assigned_to }) => assigned_to);
  const loading = getTasksList.isLoading || startTask.isLoading;
  return <>
    <Container className="container-list">
      {assignedTasks.length > 0 && <>
        <h4>Jouw taken {loading && <SmallSpinner component={null} />}</h4>
        {tasksList &&
          <DataTable
            rowsPerPage={null}
            rowClassName={rowClassName}
            filterColumn={filterColumn}
            showHeader={{ search: true }}
            data={assignedTasks}
            columns={columns}
            orderByDefault={({ project }) => project.order}
            onClickRow={selectTask}
          />
        }
        <br />
        <br />
      </>}
      <h4>Beschikbare taken {assignedTasks.length === 0 && loading && <SmallSpinner component={null} />}</h4>
        {tasksList &&
          <DataTable
            rowsPerPage={null}
            showHeader={{ search: true }}
            rowClassName={rowClassName}
            filterColumn={filterColumn}
            data={
              Object.values(tasksList).filter(({ assigned_to, finished_at }) => !assigned_to && !finished_at)
            }
            columns={columns}
            orderByDefault={({ project }) => project.order}
            textOnEmpty='Geen taken om weer te geven.'
            onClickRow={selectTask}
          />
        }
    </Container>
  </>;
};
export default TasksList;
