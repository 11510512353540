import React from 'react';
import Root from './Root';
import { Route, Routes, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Login from './components/login/Login';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { LocalizationProvider } from './components/shared/ReactToolbox';
import AuthenticatedRoute from './components/login/AuthenticatedRoute';
import Dashboard from './components/dashboard/Dashboard';
import TasksList from './components/tasks/TasksList';

const WithParams = ({ component: Component, ...restProps }) => {
  const { id, ...restParams } = useParams();
  return <Component
    {...{
      ...id ? { id: parseInt(id) } : {},
      ...restParams,
      ...restProps,
    }}
  /> ;
}

const App = () => {
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <LocalizationProvider lang='nl'>
          <Root>
            <ToastContainer hideProgressBar={true} newestOnTop={true} />
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  exact
                  path="/"
                  element={<AuthenticatedRoute component={Dashboard} />}
                >
                  {/* <Route
                    path=""
                    index={true}
                    element={<TasksList />}
                  /> */}
                </Route>
              </Routes>
          </Root>
        </LocalizationProvider>
      </DndProvider>
    </div>
  );
}

export default App;
