import { LOGIN_SET_TOKEN, LOGIN_SET_CURRENT_USER, LOGIN_UNSET_CURRENT_USER } from '../actions/Login';

const initialState = {
  isAuthenticated: false,
  user: null,
  token: ''
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SET_TOKEN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload,
      };
    case LOGIN_SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGIN_UNSET_CURRENT_USER:
      return initialState;
    default:
      return state;
  }
};
