import { setCurrentUser, setToken } from './redux/actions/Login';
import { isEmpty } from './utils/Utils';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './redux/reducer';

const Root = ({ children }) => {
  const middleware = [thunk];

  const store = createStore(
    rootReducer(),
    applyMiddleware(...middleware)
  );
  if (!isEmpty(localStorage.getItem('workflowmanager-token'))) {
    store.dispatch(setToken(localStorage.getItem('workflowmanager-token')));
  }
  if (!isEmpty(localStorage.getItem('workflowmanager-user'))) {
    const user = JSON.parse(localStorage.getItem('workflowmanager-user'));
    store.dispatch(setCurrentUser(user, ''));
  }
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
};

export default Root;
