import { combineReducers } from 'redux';

import { LOGIN_UNSET_CURRENT_USER } from './actions/Login';
import { loginReducer } from './reducers/Login';
import factory from './factory';

const consoleLogReducer = (state = null, { type, ...action }) => {
    console.log(type, action, state);
    return state;
}

export default () => {
    const rootReducer = (state, action) => consoleLogReducer(combineReducers({
        auth: loginReducer,
        ...factory.reducers,
    })(state, action), action);

    return (state, action) => {
        if (action.type === LOGIN_UNSET_CURRENT_USER) {
            return rootReducer(undefined, action)
        }

        return rootReducer(state, action)
    };
}

