import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import _ from 'lodash';

import { logout, getCurrentUser } from '../../redux/actions/Login';
import { use } from '../../redux/factory';
import { usePrevious } from '../../utils/Utils';
import { SmallSpinner, MenuButton, useInterval } from '../shared/ReactToolbox';
import TasksList from '../tasks/TasksList';
import Task from '../tasks/Task';
import Menu from './Menu';
import { orderTasksList } from '../tasks/TasksList';

const userOrUsername = user => user.name || user.username;
const Dashboard = () => {
  const { usersList, getUsersList, setWebappUser } = use.users();
  const {
    getTasksList,
    unSelectTask,
    tasksList,
    selectTask,
    selectedTask,
  } = use.tasks();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    getTasksList();
    dispatch(getCurrentUser());
  }, []);
  useInterval(getTasksList, 5*60*1000);

  useEffect(() => {
    if (user && user.is_staff && !usersList && !getUsersList.isLoading && !getUsersList.error) {
      getUsersList()
    }

  }, [user, usersList, getUsersList.isLoading, getUsersList.error])

  const previousTaskList = usePrevious(tasksList);
  let taskToSelect;
  if (tasksList && !previousTaskList) {
    // Get the first running task
    taskToSelect = orderTasksList(tasksList).find(({ assigned_to }) => assigned_to);
  }
  useEffect(() => {
    if (taskToSelect) {
      selectTask(taskToSelect);
    }
  }, [taskToSelect]);

  const previousSelectedTask = usePrevious(selectedTask);
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    if (!_.isEqual(selectedTask, previousSelectedTask)) setShowMenu(false);
  }, [selectedTask, previousSelectedTask]);

  return (
    <>
      <Navbar bg='light'>
        <Nav.Link
          onClick={() => {
            unSelectTask();
            getTasksList();
          }}
        >
          <Navbar.Brand>
            Dashboard
          </Navbar.Brand>
        </Nav.Link>

        <MenuButton
          className="p-0 d-md-none ms-3"
          size='25'
          onClick={() => setShowMenu(!showMenu)}
        />
        <Nav className="mr-auto">
          <Navbar.Text id="dashboard-title">
            <div></div>
          </Navbar.Text>
        </Nav>
        <Navbar.Collapse className='justify-content-end'>
          {user && user.is_staff ? (
            <NavDropdown
              title={
                <b>
                  {user.is_staff && user.webapp_user ? (
                    usersList ? userOrUsername(usersList[user.webapp_user]) : <SmallSpinner />
                  ) : (
                    userOrUsername(user)
                  )}
                </b>
              }
            >
              <NavDropdown.Item
                onClick={() => setWebappUser({ id: null })}
              >
                {userOrUsername(user)}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              {usersList && Object.values(usersList)
                .filter(({ is_staff }) => !is_staff)
                .map((user, index) =>
                  <NavDropdown.Item
                    key={index}
                    onClick={() => setWebappUser({ id: user.id })}
                  >
                    {userOrUsername(usersList[user.id])}
                  </NavDropdown.Item>
              )}
            </NavDropdown>
          ) : (
            <Navbar.Text>
              <b>{userOrUsername(user)}</b>
            </Navbar.Text>
          )}
          <Nav.Link onClick={() => dispatch(logout())}>Logout</Nav.Link>
        </Navbar.Collapse>
      </Navbar>

      {showMenu &&
        <div style={{ width: '100%'}} className="bg-light">
          <div className='p-0 d-md-none ms-3'>
            <div className="divider" />
            <Nav className="d-block">
              <Menu />
            </Nav>
          </div>
        </div>
      }

      <Row style={{ width: '100%'}}>
        <Col sm="2" className="sidebar">
          <Nav className="d-none d-md-block bg-light fluid"  style={{ minHeight: 'calc(100vh - 58px)'}}>
            <Menu />
          </Nav>
        </Col>
        <Col sm="10">
          {
            !tasksList || taskToSelect
            ? <SmallSpinner className='vertical-space' />
            : selectedTask
            ? <Task task={selectedTask} />
            : <TasksList />
          }
        </Col>
      </Row>
    </>
  );
};
export default Dashboard;
