import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Container, Button, Card, Alert } from 'react-bootstrap';

import { use } from '../../redux/factory';
import TaskActions from './TaskActions';
import {
  SmallSpinner,
  DataTable,
  FormTextArea,
  useDebouncedEffect,
  usePrevious,
  SaveButton,
} from '../shared/ReactToolbox';
import NotesInput from '../shared/NotesInput';

export const getTaskName = task => `${task.task_template.name} ${task.name && `(${task.name})`}`;

const Task = ({ task }) => {
  const { updateNotes } = use.tasks();

  return (
    <Container className='container-item'>
      {task.is_flagged &&
        <Alert variant="danger" className='vertical-space'>
          Deze taak direct uitvoeren
        </Alert>
      }
      <div className="float-end" >
        <TaskActions task={task} />
      </div>
      <h4>{getTaskName(task)}</h4>
      {task.contact && `${task.contact.last_name} - `}{task.project.name}

      {task.notes &&
        <Card className='vertical-space'>
          <Card.Body>
            <Card.Text>
              {task.notes}
            </Card.Text>
          </Card.Body>
        </Card>
      }
      {task.custom_fields.length > 0 &&
        <Alert variant="info" className='vertical-space'>
          <Alert.Heading>Informatie uit de offerte</Alert.Heading>
          {task.custom_fields.map((cf, key) =>
            <div key={cf.id}>
              <hr/>
              <p>
                <strong>{cf.definition.label}</strong>:&nbsp;
                {
                  cf.value === null
                  ? <i>Niet ingevoerd</i>
                  : cf.definition.type === 'boolean'
                  ? (cf.value ? 'Ja' : 'Nee')
                  : cf.value
                }
              </p>
            </div>
          )}
        </Alert>
      }

      <NotesInput
        notes={task.project.notes}
        updateNotes={(notes) => updateNotes({ task, notes })}
        loading={updateNotes.isLoading}
        id={task.id}
      />

      {task.logs.length > 0 &&
        <DataTable
          rowsPerPage={null}
          className='vertical-space'
          columns={[
            {
              className: 'd-none d-md-table-cell',
              name: 'Startdatum',
              selector: ({ started_at }) => moment(started_at).format('DD-MM-YYYY HH:mm'),
            },
            {
              className: 'd-none d-md-table-cell',
              name: 'Einddatum',
              selector: ({ finished_at }) =>
                finished_at ? moment(finished_at).format('DD-MM-YYYY HH:mm') : <i>Nog niet afgesloten</i>,
            },
            {
              name: 'Gebruiker',
              selector: ({ user: { name, username } }) => name || <i>{username}</i>,
            },
            {
              name: 'Notities',
              selector: ({ notes }) => notes,
            },
          ]}
          data={task.logs.filter(({ finished_at }) => !!finished_at)}
          showHeader={false}
          orderByDefault='started_at'
        />
      }
    </Container>
  )
};
export default Task;
