

import { useState, useEffect, ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import {
  useDebouncedEffect,
  usePrevious,
  SaveButton,
} from './ReactToolbox';

type NotesInputProps = {
    notes: string;
    updateNotes: (notes: string) => void;
    loading?: boolean;
    id?: any
}
const NotesInput = ({
  notes: notesSaved,
  updateNotes,
  loading,
  id,
}: NotesInputProps) => {

  const [notes, setNotes] = useState(notesSaved);
  const [pristine, setPristine] = useState(true);
  useDebouncedEffect(
    () => {
      if (notesSaved !== notes) {
        updateNotes(notes);
      }
    },
    [notes],
    5000
  );
  const previousId = usePrevious(id)
  useEffect(() => {
    if (pristine && notes !== notesSaved) {
      setPristine(false);
    }
    if (id && id !== previousId) {
      // Reset notes edit field when clicking on a different update function
      setNotes(notesSaved);
    }
  }, [notes, id, previousId, pristine])

  return (
    <div>
      <div className='vertical-space'>
        <div className="float-end">
          <SaveButton
            className='vertical-space'
            variant="primary"
            size="sm"
            disabled={notesSaved === notes}
            onClick={() => updateNotes(notes)}
            title=""
            loading={loading}
          >
            Opslaan
          </SaveButton>
        </div>

        {!pristine && notes !== notesSaved &&
          <i>&nbsp;(niet opgeslagen)</i>
        }
      </div>

      <Form.Control
        autoComplete="off"
        value={notes}
        rows={5}
        as='textarea'
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNotes(e.target.value)}
      />
    </div>
  )
};
export default NotesInput;
