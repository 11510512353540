import { use } from '../../redux/factory';
import { orderTasksList } from '../tasks/TasksList';

const Menu = () => {
  const { tasksList, getTask, selectTask } = use.tasks();
  if (!tasksList) return null;
  return(
    orderTasksList(tasksList)
      .filter(({ assigned_to }) => assigned_to)
      .map((task, index) =>
        <div className="nav-item" key={index}>
          <div
            className="nav-link"
            onClick={() => {
              getTask(task);
              selectTask(task);
            }}
          >
            {task.task_template.name}
          </div>
        </div>
      )
  );
}
export default Menu;
