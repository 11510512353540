import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
const AuthenticatedRoute = ({ component: Component, redirectNonStaffUserTo, ...restProps }) => {
  const params = useParams();
  const { isAuthenticated, user } = useSelector(({ auth }) => auth);
  if (isAuthenticated && user && !user.is_staff && redirectNonStaffUserTo) {
    window.location = redirectNonStaffUserTo;
    return null;
  }
  return isAuthenticated ? <Component {...params} {...restProps} /> : <Navigate to="/login" />;
}

export default AuthenticatedRoute;
